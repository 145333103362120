// Dependencies
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Alert, Box, InputLabel, Snackbar } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import LoadingButton from '@mui/lab/LoadingButton';

// Components
import Section from '../../components/common/AddProductSection'
import { InputBaseComponent, TextFieldInput } from '../../components/common/Inputs'

// Utils
import { getLoggedUser, userSelector } from '../../redux/features/userStateSlice'
import Selector, { Options } from '../../components/common/Selector';
import { phoneCountryCodeList, countryList, parcelsCountList, mapWeightLabel } from '../../utils/utilities-utils';
import colorConfigs from '../../configs/colorConfigs';
import { createdLabelIdSelector, isLoadingPringSelector, locationsSelector, snackbarSelector } from './states/reducer';
import { clearFeedbackMessage, createLabel, getAllLocations, printLabel } from './states/actions';

const CreateLabel = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const user = getLoggedUser(useSelector)
    const isLoadingPrint = useSelector(isLoadingPringSelector)
    const locations = useSelector(locationsSelector)
    const snackbar = useSelector(snackbarSelector)
    const createdLabelId = useSelector(createdLabelIdSelector)

    const [numberOfParcels, setNumberOfParcels] = React.useState<number>(0)
    const [name, setName] = React.useState<string>('')
    const [address, setAddress] = React.useState<string>('')
    const [adjunct, setAdjunct] = React.useState<string>('')
    const [city, setCity] = React.useState<string>('')
    const [country, setCountry] = React.useState<string>('')
    const [email, setEmail] = React.useState<string>('')
    const [code, setCode] = React.useState<string>('')
    const [phone, setPhone] = React.useState<string>('')
    const [weightList, setWeightList] = React.useState<any>({ 0: '', 1: '', 2: '' })
    const [postalCode, setPostalCode] = React.useState<string>('')
    const [ownerId, setOwnerId] = React.useState<string>('')
    const [locationsOpts, setLocationsOpts] = React.useState<Options[]>([])

    React.useEffect(() => {
        if (user.isAdmin) {
            dispatch(getAllLocations())
        }
    }, [])

    React.useEffect(() => {
        const data: Options[] = locations.map(loc => ({
            label: loc.ownerId,
            value: loc.ownerId
        }))
        setLocationsOpts(data)
    }, [locations])

    React.useEffect(() => {
        if (createdLabelId) {
            dispatch(printLabel({ id: createdLabelId }))
        }
    }, [createdLabelId])

    const handleCloseSnackbar = React.useCallback(
        () => {
            dispatch(clearFeedbackMessage())
            if (snackbar?.severity === 'success') {
                setNumberOfParcels(0)
                setName('')
                setAddress('')
                setAdjunct('')
                setCity('')
                setCountry('')
                setEmail('')
                setCode('')
                setPhone('')
                setWeightList({ 0: '', 1: '', 2: '' })
                setPostalCode('')
                setOwnerId('')
                setLocationsOpts([])
            }

        }, [dispatch, snackbar]
    )
    
    const isDisabled = (): boolean => {
        if (user.isAdmin && !ownerId) return true
        return (
            !name ||
            !address ||
            !numberOfParcels ||
            !city ||
            !country ||
            !postalCode ||
            checkNumberOfFilledWeights()
        )
    }

    const handleListSize = React.useCallback((): number[] => {
        return Array(numberOfParcels).fill(0)
    }, [numberOfParcels, weightList])

    const formatPhone = (code: number, phone: string): string => {
        if (phone) {
            return '+' + code + phone
        }
        return ''
    }

    const checkNumberOfFilledWeights = (): boolean => {
        let count = 0
        for (let param in weightList) {
            const val = weightList[param]
            if (val !== '') count ++
        }
        return count !== numberOfParcels
    }

    const setTotalWeight = (): number => {
        let sum = 0
        for (let param in weightList) {
            sum += Number(weightList[param])
        }
        return sum * 1000
    }
    
    return (
        <Box style={{ width: '100%' }}>
            <Section >
                <InputBaseComponent
                    disabled={false}
                    label={t('utilities.createLabel.inputs.first.label')}
                    id='utilities-name-input'
                    fullWidth
                    style={{ marginBottom: '1.3vw' }}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <InputLabel shrink sx={{ fontWeight: 'medium', fontSize: '1.4vw' }}>
                    {t('utilities.createLabel.inputs.second.label')}
                </InputLabel>
                <Selector
                    sx={{
                        marginBottom: '2vw',
                        '& .MuiSelect-select': {
                            backgroundColor: colorConfigs.mainBg,
                        }
                    }}
                    options={countryList}
                    value={country}
                    handleSelect={(val) => setCountry(val)}
                />
                <InputBaseComponent
                    disabled={false}
                    label={t('utilities.createLabel.inputs.third.label')}
                    id='utilities-address-input'
                    fullWidth
                    style={{ marginBottom: '1.3vw' }}
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                />
                <InputBaseComponent
                    disabled={false}
                    label={t('utilities.createLabel.inputs.fourth.label')}
                    id='utilities-additional-info-input'
                    fullWidth
                    style={{ marginBottom: '1.3vw' }}
                    value={adjunct}
                    onChange={(e) => setAdjunct(e.target.value)}
                />
                <div style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'space-between',
                }}>
                    <InputBaseComponent
                        disabled={false}
                        label={t('utilities.createLabel.inputs.fifth.label')}
                        id='utilities-postal-code-input'
                        style={{ marginBottom: '1.3vw', marginRight: '1vw' }}
                        value={postalCode}
                        onChange={(e) => setPostalCode(e.target.value)}
                    />
                    <InputBaseComponent
                        disabled={false}
                        label={t('utilities.createLabel.inputs.sixth.label')}
                        id='utilities-city-input'
                        style={{ marginBottom: '1.3vw', width: '100%' }}
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                    />
                </div>
                <InputBaseComponent
                    disabled={false}
                    label={t('utilities.createLabel.inputs.seventh.label')}
                    id='utilities-email-input'
                    fullWidth
                    style={{ marginBottom: '1.3vw' }}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <InputLabel shrink sx={{ fontWeight: 'medium', fontSize: '1.4vw' }}>
                    {t('utilities.createLabel.inputs.eigth.label')}
                </InputLabel>
                <div
                    style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Selector
                        sx={{
                            marginRight: '1vw',
                            '& .MuiSelect-select': {
                                backgroundColor: colorConfigs.mainBg,
                            },
                            'label + &': {
                                marginTop: '2vw',
                            },
                        }}
                        options={phoneCountryCodeList}
                        value={code}
                        handleSelect={val => setCode(val)}
                    />
                    <TextFieldInput
                        fullWidth
                        placeholder={t('utilities.createLabel.inputs.eigth.placeholder')}
                        sx={{ 
                            width: '100%',

                            '& .MuiInputBase-input': {
                                backgroundColor: colorConfigs.mainBg,
                                padding: '0.5vw',
                                width: '100%',
                                border: '1px solid',
                                borderColor: '#E0E3E7',
                                fontSize: '1.2vw',
                            },
                            '&:focus': {},
                        }}
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                    />
                </div>
            </Section>
            <Section>
                <InputLabel shrink sx={{ fontWeight: 'medium', fontSize: '1.4vw' }}>
                    {t('utilities.createLabel.inputs.ninth.label')}
                </InputLabel>
                <Selector
                    sx={{
                        '& .MuiSelect-select': {
                            backgroundColor: colorConfigs.mainBg,
                        },
                        marginBottom: '1.3vw',
                    }}
                    value={numberOfParcels.toString()}
                    options={parcelsCountList}
                    handleSelect={val => setNumberOfParcels(parseInt(val))}
                />
                {handleListSize().map((el, index) => (
                    <div key={mapWeightLabel(index + 1)}>
                        <InputLabel key={index + '-input'} shrink sx={{ fontWeight: 'medium', fontSize: '1.4vw' }}>
                            {`${t('utilities.createLabel.parcel')} ${index + 1} `}
                        </InputLabel>
                        <InputBaseComponent
                            key={index}
                            placeholder={t('utilities.createLabel.inputs.ninth.placeholder')}
                            fullWidth
                            style={{
                                marginBottom: '1.3vw',
                                marginTop: 0,
                            }}
                            value={weightList[index]}
                            onChange={(e) => {
                                if (Number(e.target.value) <= 15 || e.target.value.includes('.')) {
                                    setWeightList((prevState: { [x: string]: number; }) => {
                                        return ({
                                            ...prevState,
                                            [index]: e.target.value
                                        })
                                    })
                                }
                            }}
                            type='number'
                        />
                    </div>
                ))}
            </Section>
            {user.isAdmin && (
                <Section>
                    <InputLabel shrink sx={{ fontWeight: 'medium', fontSize: '1.4vw' }}>
                        {t('utilities.createLabel.inputs.tenth.label')}
                    </InputLabel>
                    <Selector
                        sx={{
                            marginBottom: '2vw',
                            '& .MuiSelect-select': {
                                backgroundColor: colorConfigs.mainBg,
                            }
                        }}
                        options={locationsOpts}
                        handleSelect={(val) => setOwnerId(val)}
                    />
                </Section>
            )}
            <LoadingButton
                variant='contained'
                disabled={isDisabled()}
                fullWidth
                loading={isLoadingPrint}
                onClick={() => {
                    const label = {
                        ownerId,
                        name,
                        address,
                        adjunct,
                        city,
                        country,
                        postalCode,
                        numberOfParcels,
                        weight: setTotalWeight(),
                        email,
                        phone: formatPhone(Number(code), phone),
                    }
                    console.log(label)
                    dispatch(createLabel(label))
                }}
            >
                {t('utilities.createLabel.button')}
            </LoadingButton>
            {!!snackbar && (
                <Snackbar
                    open
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    onClose={handleCloseSnackbar}
                    autoHideDuration={1000}
                >
                    <Alert {...snackbar} onClose={handleCloseSnackbar} />
                </Snackbar>
            )}
        </Box>
    )
}

export default CreateLabel
