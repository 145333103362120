
// Types
import { UtilitiesActionsTypes } from './types'

import { User, UserLocation } from '../../../types/user'
import { CreateLabelRequest, LabelPayload, PrintLabel } from '../../../types/labels'

export function updateUser(
    payload: {
        user: User,
        userId?: string,
    }
) {
    return {
        type: UtilitiesActionsTypes.UPDATE_USER,
        payload,
    }
}

export function updateUserSuccess(
    payload: User[]
) {
    return {
        type: UtilitiesActionsTypes.UPDATE_USER_SUCCESS,
        payload,
    }
}

export function updateUserError(
    payload: any,
) {
    return {
        type: UtilitiesActionsTypes.UPDATE_USER_ERROR,
        payload,
    }
}

export function getAllLocations(
    payload?: User
) {
    return {
        type: UtilitiesActionsTypes.GET_ALL_LOCATIONS,
        payload,
    }
}

export function getAllLocationsSuccess(
    payload: UserLocation[]
) {
    return {
        type: UtilitiesActionsTypes.GET_ALL_LOCATIONS_SUCCESS,
        payload,
    }
}

export function getAllLocationsError(
    payload: any
) {
    return {
        type: UtilitiesActionsTypes.GET_ALL_LOCATIONS_ERROR,
        payload,
    }
}

export function updateByLocation(
    payload: {
        location: UserLocation,
    }
) {
    return {
        type: UtilitiesActionsTypes.UPDATE_BY_LOCATION,
        payload,
    }
}

export function updateByLocationSuccess(
    payload: UserLocation[],
    
) {
    return {
        type: UtilitiesActionsTypes.UPDATE_BY_LOCATION_SUCCESS,
        payload,
    }
}

export function updateByLocationError(
    payload: any
) {
    return {
        type: UtilitiesActionsTypes.UPDATE_BY_LOCATION_ERROR,
        payload,
    }
}

export function getLabels(
    payload?: {
        ownerId?: string,
    }
) {
    return {
        type: UtilitiesActionsTypes.GET_LABELS,
        payload,
    }
}

export function getLabelsSuccess(
    payload: LabelPayload[],
) {
    return {
        type: UtilitiesActionsTypes.GET_LABELS_SUCCESS,
        payload,
    }
}

export function getLabelsError(
    payload: any
) {
    return {
        type: UtilitiesActionsTypes.GET_LABELS_ERROR,
        payload,
    }
}

export function createLabel(
    payload: LabelPayload
) {
    return {
        type: UtilitiesActionsTypes.CREATE_LABEL,
        payload,
    }
}

export function createLabelSuccess(
    payload: any,
) {
    return {
        type: UtilitiesActionsTypes.CREATE_LABEL_SUCCESS,
        payload,
    }
}

export function createLabelError(
    payload: any
) {
    return {
        type: UtilitiesActionsTypes.CREATE_LABEL_ERROR,
        payload,
    }
}

export function printLabel(
    payload: PrintLabel
) {
    return {
        type: UtilitiesActionsTypes.PRINT_LABEL,
        payload,
    }
}

export function printLabelSuccess(
    payload: any,
) {
    return {
        type: UtilitiesActionsTypes.PRINT_LABEL_SUCCESS,
        payload,
    }
}

export function printLabelError(
    payload: any
) {
    return {
        type: UtilitiesActionsTypes.PRINT_LABEL_ERROR,
        payload,
    }
}

export function clearFeedbackMessage() {
    return {
        type: UtilitiesActionsTypes.CLEAR_FEEDBACK_MESSAGE,
    }
}