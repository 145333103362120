// Dependencies
import { ofType, combineEpics, StateObservable } from 'redux-observable'
import { catchError, map, mergeMap, finalize } from 'rxjs/operators'
import { from, Observable, of } from 'rxjs'
// Utils
import { GetAllLocationsAction, UtilitiesActionsTypes, UpdateUserAction, UtilitiesActions, CreateLabelAction, GetLabelsAction, PrintLabelAction } from './types'
import { UsersService } from '../../../services/users'
import { clearFeedbackMessage, createLabelError, createLabelSuccess, getAllLocationsError, getAllLocationsSuccess, getLabelsError, getLabelsSuccess, printLabel, printLabelSuccess, updateByLocationError, updateByLocationSuccess, updateUserError, updateUserSuccess } from './actions'
import { translate } from '../../../i18n'
import { deepCloneObject } from '../../../utils/object-utils'
import { locationsSelector, usersSelector } from './reducer'
import { RootState } from '../../../redux/store'
import { User, UserLocation } from '../../../types/user'
import { LabelsService } from '../../../services/labels'
import { CreateLabelRequest } from '../../../types/labels'
import { isAdminSelector, userSelector } from '../../../redux/features/userStateSlice'

const usersService = new UsersService()
const labelsService = new LabelsService()

const getAllLocationsEpic = (
    actions$: Observable<UtilitiesActions>,
): any =>
    actions$.pipe(
        ofType<UtilitiesActions, UtilitiesActionsTypes.GET_ALL_LOCATIONS, GetAllLocationsAction>(UtilitiesActionsTypes.GET_ALL_LOCATIONS),
        mergeMap(() =>
            from(usersService.find()).pipe(
                map(({ data }) => {
                    return getAllLocationsSuccess(data)
                }),
                catchError((error) => {
                    console.log(error)
                    return of(getAllLocationsError({ children: translate('general.apiError'), severity: 'error' }))
                }),
                finalize(() => clearFeedbackMessage())
            )
        )
    )

const updateUserEpic = (
    actions$: Observable<UtilitiesActions>,
    state$: StateObservable<RootState>
): any =>
    actions$.pipe(
        ofType<UtilitiesActions, UtilitiesActionsTypes.UPDATE_USER, UpdateUserAction>(UtilitiesActionsTypes.UPDATE_USER),
        mergeMap(({ payload: { user, userId } }) =>
            from(usersService.update(user, userId)).pipe(
                map(({ data }) => {
                    let users: User[] = deepCloneObject(usersSelector(state$.value));
                    const updatedUsers = users.map(user => {
                        if (user.userId === data.userId) {
                            return data
                        }
                        return user
                    })
                    return updateUserSuccess(updatedUsers)
                }),
                catchError((error) => {
                    console.log(error)
                    return of(updateUserError({ children: translate('requests.feedback.update.error'), severity: 'error' }))
                }),
                finalize(() => clearFeedbackMessage())
            )        
        )
    )

const updateByLocationEpic = (
    actions$: Observable<UtilitiesActions>,
    state$: StateObservable<RootState>
): any =>
    actions$.pipe(
        ofType<UtilitiesActions, UtilitiesActionsTypes.UPDATE_BY_LOCATION, UpdateUserAction>(UtilitiesActionsTypes.UPDATE_BY_LOCATION),
        mergeMap(({ payload: { location } }) =>
            from(usersService.updateByLocation(location)).pipe(
                map(({ data }) => {
                    let locations: UserLocation[] = deepCloneObject(locationsSelector(state$.value));
                    const updatedLocations = locations.map(location => {
                        if (location.id === data.id) {
                            return data
                        }
                        return location
                    })
                    return updateByLocationSuccess(updatedLocations)
                }),
                catchError((error) => {
                    console.log(error)
                    return of(updateByLocationError({ children: translate('requests.feedback.update.error'), severity: 'error' }))
                }),
                finalize(() => clearFeedbackMessage())
            )        
        )
    )

const getLabels = (
    actions$: Observable<UtilitiesActions>,
): any =>
    actions$.pipe(
        ofType<UtilitiesActions, UtilitiesActionsTypes.GET_LABELS, GetLabelsAction>(UtilitiesActionsTypes.GET_LABELS),
        mergeMap(() =>
            from(labelsService.get()).pipe(
                map(({ data }) => {
                    return getLabelsSuccess(data)
                }),
                catchError((error) => {
                    console.log(error)
                    return of(getLabelsError({ children: `Error geting labels`, severity: 'error' }))
                }),
                finalize(() => clearFeedbackMessage())
            )        
        )
    )

const createLabel = (
    actions$: Observable<UtilitiesActions>,
    state$: StateObservable<RootState>
): any =>
    actions$.pipe(
        ofType<UtilitiesActions, UtilitiesActionsTypes.CREATE_LABEL, CreateLabelAction>(UtilitiesActionsTypes.CREATE_LABEL),
        mergeMap(({ payload }) => {
            const user = userSelector(state$.value)
            const data: CreateLabelRequest = {
                ddpOrderId: payload.ddpOrderId!,
                address: {
                    name: payload.name,
                    address: payload.address,
                    adjunct: payload.adjunct,
                    city: payload.city,
                    country: payload.country,
                    postalCode: payload.postalCode,
                },
                numberOfParcels: payload.numberOfParcels,
                weight: payload.weight,
                email: payload.email,
                phone: payload.phone!,
            }

            if (user.isAdmin) {
                data.ownerId = payload.ownerId
            }

            return from(labelsService.create(data)).pipe(
                map(({ data }) => 
                    createLabelSuccess({
                        id: data.id,
                        snackbar: { children: `Label successfully created`, severity: 'success' },
                    })
                ),
                catchError((error) => {
                    console.log(error)
                    return of(createLabelError({ children: `Error creating label`, severity: 'error' }))
                }),
                finalize(() => clearFeedbackMessage())
            )
        })
    )

const printCustomLabel = (
    actions$: Observable<UtilitiesActions>,
): any =>
    actions$.pipe(
        ofType<UtilitiesActions, UtilitiesActionsTypes.PRINT_LABEL, PrintLabelAction>(UtilitiesActionsTypes.PRINT_LABEL),
        mergeMap(({ payload: { id }}) => {
            return from(labelsService.print({ id })).pipe(
                map(({ data, status }) => {
                    if (status !== 204) {
                        const url = window.URL.createObjectURL(new Blob([data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', `label-${id}.pdf`);
                        document.body.appendChild(link);
                        link.click();
                    }
                    return printLabelSuccess({ children: `Label successfully printed`, severity: 'success' })
                }),
                catchError((error) => {
                    console.log(error)
                    return of(createLabelError({ children: `Error printing label`, severity: 'error' }))
                }),
                finalize(() => clearFeedbackMessage())
            )
        })
    )

export const utilitiesEpic: any = combineEpics(
    getAllLocationsEpic,
    updateUserEpic,
    updateByLocationEpic,
    getLabels,
    createLabel,
    printCustomLabel,
)