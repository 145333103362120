// Dependencies
import LocalMallIcon from '@mui/icons-material/LocalMall'
import ListAltIcon from '@mui/icons-material/ListAlt'
import PrintIcon from '@mui/icons-material/Print'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import QueueIcon from '@mui/icons-material/Queue'
import { Outlet } from 'react-router-dom'

// Pages
import ManageProductsPage from '../pages/products/manage/ManageProductsPage'
import OrdersPage from '../pages/orders/OrdersPage'
import AddProductPage from '../pages/products/add/AddProductsPage'
import RequestsPage from '../pages/requests/RequestsPage'

// Utils
import { RouteType } from './config';
import ManagePrint from '../pages/utilities/ManagePrint'
import CreateLabel from '../pages/utilities/CreateLabel'
import RequestedLabels from '../pages/utilities/RequestedLabels'

const appRoutes: RouteType[] = [
  {
    path: '/products/add',
    element: <AddProductPage />,
    state: 'products.add',
    sidebarProps: {
      displayText: 'Add',
      icon: <AddCircleIcon sx={{ width: '1.8vw' }} />,
      textPath: 'sidebar.products.add',
    },
  },
  {
    path: '/products/manage',
    element: <ManageProductsPage />,
    state: 'products.manage',
    sidebarProps: {
      displayText: 'Manage Products',
      icon: <LocalMallIcon sx={{ width: '1.8vw' }} />,
      textPath: 'sidebar.products.manage',
    },
  },
  {
    path: '/orders/manage',
    element: <OrdersPage />,
    state: 'orders.manage',
    sidebarProps: {
      displayText: 'Orders',
      icon: <ListAltIcon sx={{ width: '1.8vw' }} />,
      textPath: 'sidebar.orders.manage',
    },
  },
  {
    path: '/requests',
    element: <Outlet />,
    state: 'requests',
    sidebarProps: {
      icon: <QueueIcon sx={{ width: '1.8vw' }} />,
      textPath: 'sidebar.requests.main',
    },
    adminRoute: true,
    child: [
      {
        path: '/requests/delete-product',
        element: <RequestsPage />,
        state: 'requests.delete-product',
        sidebarProps: {
          textPath: 'sidebar.requests.delete-product',
        },
      },
      {
        path: '/requests/change-price',
        element: <RequestsPage />,
        state: 'requests.change-price',
        sidebarProps: {
          textPath: 'sidebar.requests.change-price',
        },
      },
    ]
  },
  {
    path: '/utilities',
    element: <Outlet />,
    state: 'utilities',
    sidebarProps: {
      displayText: 'Print',
      icon: <PrintIcon sx={{ width: '1.8vw' }} />,
      textPath: 'sidebar.utilities.main',
    },
    child: [
      {
        path: '/utilities/manage-label',
        state: 'utilities.manage-label',
        element: <ManagePrint />,
        sidebarProps: {
          textPath: 'sidebar.utilities.manage-label',
        },
        adminRoute: true,
      },
      {
        path: '/utilities/create-label',
        element: <CreateLabel />,
        state: 'utilities.create-label',
        sidebarProps: {
          textPath: 'sidebar.utilities.create-label',
        },
      },
      {
        path: '/utilities/requested-labels',
        element: <RequestedLabels />,
        state: 'utilities.requested-labels',
        sidebarProps: {
          textPath: 'sidebar.utilities.requested-labels',
        },
      },
    ]
  },
];

export default appRoutes

