// Dependencies
import React, { Fragment } from 'react'
import {
    DataGrid,
    GridCellParams,
    GridColDef,
    GridRenderCellParams,
    useGridApiRef,
} from '@mui/x-data-grid';
import Alert from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'
import { Box, colors } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux';
import { alpha } from '@mui/material/styles'
import LoadingButton from '@mui/lab/LoadingButton';

// Components
import CircularLoader from '../../components/common/CircularLoader'

// Configs
import colorConfigs from '../../configs/colorConfigs'

// Services
import { isLoadingPringSelector, isLoadingSelector, labelsSelector, snackbarSelector, usersSelector } from './states/reducer';
import { clearFeedbackMessage, getLabels, createLabel, updateUserError, printLabel, } from './states/actions';
import moment from 'moment';
import { LabelPayload } from '../../types/labels';
import { getLoggedUser } from '../../redux/features/userStateSlice';

const RequestedLabels = () => {
    const { t } = useTranslation()
    const apiRef = useGridApiRef()
    const dispatch = useDispatch()
    
    const labels = useSelector(labelsSelector)
    const isLoading = useSelector(isLoadingSelector)
    const snackbar = useSelector(snackbarSelector)
    const isLoadingPrint = useSelector(isLoadingPringSelector)
    const user = getLoggedUser(useSelector)

    const mainColums: GridColDef[] = [
        {
            field: 'date',
            headerName: t('utilities.requestedLabels.table.header.date'), 
            headerClassName: 'table-header',
            renderCell(params: GridRenderCellParams) {
                const { row: { date } } = params

                return (
                    <div style={{ alignItems: 'center', display: 'flex' }}>
                        <Fragment>{moment(date).format('DD/MM/YYYY')}</Fragment>
                    </div>
                )
            },
            flex: 0.3,
        },
        {
            field: 'name',
            headerName: t('utilities.requestedLabels.table.header.name'), 
            headerClassName: 'table-header',
            renderCell(params: GridRenderCellParams) {
                const { row: { name } } = params

                return (
                    <div style={{ alignItems: 'center', display: 'flex' }}>
                        <Fragment>{name}</Fragment>
                    </div>
                )
            },
            flex: 1,
        },
        {
            field: 'city',
            headerName: t('utilities.requestedLabels.table.header.city'), 
            headerClassName: 'table-header',
            renderCell(params: GridRenderCellParams) {
                const { row: { city } } = params

                return (
                    <div style={{ alignItems: 'center', display: 'flex' }}>
                        <Fragment>{city}</Fragment>
                    </div>
                )
            },
            flex: 0.5,
        },
        {
            field: 'country',
            headerName: t('utilities.requestedLabels.table.header.country'), 
            headerClassName: 'table-header',
            renderCell(params: GridRenderCellParams) {
                const { row: { country } } = params

                return (
                    <div style={{ alignItems: 'center', display: 'flex' }}>
                        <Fragment>{country}</Fragment>
                    </div>
                )
            },
            flex: 0.4,
        },
        {
            field: 'parcels',
            headerName: t('utilities.requestedLabels.table.header.parcels'), 
            headerClassName: 'table-header',
            renderCell(params: GridRenderCellParams) {
                const { row: { numberOfParcels } } = params

                return (
                    <div style={{ alignItems: 'center', display: 'flex' }}>
                        <Fragment>{numberOfParcels}</Fragment>
                    </div>
                )
            },
            flex: 0.3,
        },
        {
            field: 'tracking',
            headerName: t('utilities.requestedLabels.table.header.tracking'), 
            headerClassName: 'table-header',
            renderCell(params: GridRenderCellParams) {
                const { row: { trackingId } } = params

                return (
                    <div style={{ alignItems: 'center', display: 'flex' }}>
                        <Fragment>{trackingId}</Fragment>
                    </div>
                )
            },
            flex: 0.5,
        },
        {
            field: 'action',
            headerName: t('utilities.requestedLabels.table.header.actions.title'),
            align: 'center',
            headerAlign: 'center',
            headerClassName: 'table-header',
            renderCell(params: GridRenderCellParams) {
                const value = t('utilities.requestedLabels.table.header.actions.button')
                const label = params.row as LabelPayload
                return (
                    <div style={{ alignItems: 'center', display: 'flex', width: "100%", justifyContent: "center" }}>
                        <LoadingButton 
                            size='small'
                            variant='contained'
                            // onClick={() => dispatch(createLabel(label))}
                            onClick={() => dispatch(printLabel({ id: label.ddpOrderId! }))}
                            sx={{ fontSize: '0.8vw' }}
                            loading={isLoadingPrint}
                        >
                            {value}
                        </LoadingButton>
                    </div>
                )
            },
            flex: 0.25,
        },
    ]

    let columns = mainColums

    React.useEffect(() => {
        dispatch(getLabels())
    }, [])


    if (user.isAdmin) {
        columns = [
            ...mainColums.slice(0, mainColums.length - 1),
            {
                field: 'locations',
                headerName: t('utilities.requestedLabels.table.header.location'), 
                headerClassName: 'table-header',
                renderCell(params: GridRenderCellParams) {
                    const { row: { ownerId } } = params
    
                    return (
                        <div style={{ alignItems: 'center', display: 'flex' }}>
                            <Fragment>{ownerId}</Fragment>
                        </div>
                    )
                },
                flex: 0.5,
            },
            ...mainColums.slice(mainColums.length - 1, mainColums.length),
        ]
    }

    const handleCloseSnackbar = () => dispatch(clearFeedbackMessage())

    const handleProcessRowUpdateError = React.useCallback(() => {
        dispatch(updateUserError({ children: t('utilities.manage.feedback.save.error'), severity: 'error' }))
    }, [dispatch, t]);

    if (isLoading) {
        return <CircularLoader />
    }
    
    return (
        <Box
            sx={{
                width: '100%',
                flexGrow: 1,
                '& .table-header': {
                    backgroundColor: colorConfigs.topbar.bg,
                    fontSize: '1vw',
                },
                '& .disabled': {
                    backgroundColor: alpha(colors.grey['300'], 0.4),
                    fontSize: '0.9vw',
                    padding: 2,
                },
                '& .row': {
                    fontSize: '0.9vw',
                    padding: 2,
                },
            }}
        >    
            <DataGrid
                getRowId={(row) => {
                    // console.log(row)
                    return row.id
                }}
                rows={labels}
                columns={columns}
                getCellClassName={(params: GridCellParams<any, any, number>) => {
                    // if (isProductDisabled(params, rows)) {
                    //     return 'disabled'
                    // }
                    return 'row'
                }}
                showCellVerticalBorder
                columnThreshold={20}
                showColumnVerticalBorder
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                apiRef={apiRef}
                onProcessRowUpdateError={handleProcessRowUpdateError}
                autoPageSize
            />
            {!!snackbar && (
                <Snackbar
                    open
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    onClose={handleCloseSnackbar}
                    autoHideDuration={3000}
                >
                    <Alert {...snackbar} onClose={handleCloseSnackbar} />
                </Snackbar>
            )}
        </Box>
    );
}

export default RequestedLabels