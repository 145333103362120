// Dependencies
import { AxiosResponse } from 'axios'

// Services
import { HttpService } from './http'

// Types
import { User, GetUserQueryParams, UserLocation, UserLanguage } from '../types/user'
import { currentLanguageKey, setItem } from '../utils/storage-utils'

export class UsersService extends HttpService {
    private path: string

    constructor() {''
        super()
        this.path = '/users'
    }

    get = (): Promise<AxiosResponse<User>> => 
        this.request({ url: this.path, method: 'get' })

    find = (params?: GetUserQueryParams): Promise<AxiosResponse<UserLocation[]>> => 
        this.request({ url: `${this.path}/locations`, method: 'get', params })

    setLanguage = async (data: UserLanguage): Promise<AxiosResponse<UserLanguage>> => {
        const res = await this.request({ url: `${this.path}/language`, method: 'put', data })
        const { language } = res.data
        setItem(currentLanguageKey, language)
        return res
    }

    getAll = (params?: GetUserQueryParams): Promise<AxiosResponse<User[]>> =>
        this.request({ url: `${this.path}/all`, method: 'get', params })

    update = (data: Partial<User>, userId?: string): Promise<AxiosResponse<User>> =>
        this.request({ url: `${this.path}/update`, method: 'post', params: { userId }, data })

    updateByLocation = (data: Partial<UserLocation>): Promise<AxiosResponse<UserLocation>> =>
        this.request({ url: `${this.path}/updateByLocation`, method: 'post', params: { location: data.ownerId }, data })
}