import { Options } from "../components/common/Selector"
import { translate } from '../i18n';

export const countryList: Options[] = [
    {
        label: ``,
        value: ''
    },
    {
        label: `🇱🇺 ${translate('countries.luxembourg')}`,
        value: 'Luxembourg'
    },
    {
        label: `🇧🇪 ${translate('countries.belgium')}`,
        value: 'Belgium'
    },
    {
        label: `🇫🇷 ${translate('countries.france')}`,
        value: 'France'
    },
    {
        label: `🇳🇱 ${translate('countries.netherlands')}`,
        value: 'Netherlands'
    },
]

export const phoneCountryCodeList: Options[] = [
    {
        label: '',
        value: '',
    },
    {
        label: '🇱🇺',
        value: 352,
    },
    {
        label: '🇧🇪',
        value: 32,
    },
    {
        label: '🇫🇷',
        value: 33,
    },
    {
        label: '🇳🇱',
        value: 31,
    },
]

export const parcelsCountList: Options[] = [
    {
        label: '',
        value: 0,
    },
    {
        label: '1',
        value: 1,
    },
    {
        label: '2',
        value: 2,
    },
    {
        label: '3',
        value: 3,
    },
] 

export const mapWeightLabel = (index: number): string => {
    switch (index) {
        case 1:
            return 'first'
        case 2:
            return 'second'
        case 3:
            return 'third'
        default:
            return `first`
    }
}