// Dependencies
import { AxiosResponse } from 'axios'

// Services
import { HttpService } from './http'

// Types
import { CreateLabelRequest, GetLabelsParams, LabelPayload, PrintLabel } from '../types/labels'

export class LabelsService extends HttpService {
    private path: string

    constructor() {
        super()
        this.path = '/labels'
    }

    get = (params?: GetLabelsParams): Promise<AxiosResponse<LabelPayload[]>> =>
        this.request({ method: 'get', url: this.path, params })
    
    create = (data: CreateLabelRequest): Promise<AxiosResponse<any>> =>
        this.request({
            method: 'post',
            url: `${this.path}/create`,
            data,
        })

    print = (params: PrintLabel): Promise<AxiosResponse<any>> =>
        this.request({
            method: 'get',
            url: `${this.path}/print`,
            params,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
            },
            responseType: 'arraybuffer',
        })    
}