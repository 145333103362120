// Dependencies
import { combineReducers, configureStore, Action } from '@reduxjs/toolkit'
import { combineEpics, createEpicMiddleware } from 'redux-observable'

// Reducers & Epics
import { manageProductsEpic, manageProductsReducer } from '../pages/products/manage/states'
import appStateSlice from './features/appStateSlice'
import userStateSlice from './features/userStateSlice'
import { addProductEpic, addProductReducer } from '../pages/products/add/states'
import { manageOrdersReducer, maganeOrdersEpic } from '../pages/orders/states'
import { requestsReducer, requestsEpic } from '../pages/requests/states'
import { utilitiesReducer, utilitiesEpic } from '../pages/utilities/states'

const rootEpic: any = combineEpics(
  manageProductsEpic,
  addProductEpic,
  maganeOrdersEpic,
  requestsEpic,
  utilitiesEpic,
)

const rootReducer = combineReducers({
  manageProducts: manageProductsReducer,
  appState: appStateSlice,
  addProduct: addProductReducer,
  manageOrders: manageOrdersReducer,
  userState: userStateSlice,
  requests: requestsReducer,
  utilities: utilitiesReducer,
})

const epicMiddleware = createEpicMiddleware<
  Action<any>,
  Action<any>,
  RootState
>();

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false, immutableCheck: false }).concat(epicMiddleware)
})

epicMiddleware.run(rootEpic)

export type RootState = ReturnType<typeof rootReducer>
