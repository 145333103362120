import { CreateLabelRequest, LabelPayload, PrintLabel } from "../../../types/labels"
import { User, UserLocation } from "../../../types/user"

export enum UtilitiesActionsTypes {
  GET_ALL_LOCATIONS = '@utilities/GET_ALL_LOCATIONS',
  GET_ALL_LOCATIONS_SUCCESS = '@utilities/GET_ALL_LOCATIONS_SUCCESS',
  GET_ALL_LOCATIONS_ERROR = '@utilities/GET_ALL_LOCATIONS_ERROR',
  UPDATE_USER = '@utilities/UPDATE_USER',
  UPDATE_USER_SUCCESS = '@utilities/UPDATE_USER_SUCCESS',
  UPDATE_USER_ERROR = '@utilities/UPDATE_USER_ERROR',
  UPDATE_BY_LOCATION = '@utilities/UPDATE_BY_LOCATION',
  UPDATE_BY_LOCATION_SUCCESS = '@utilities/UPDATE_BY_LOCATION_SUCCESS',
  UPDATE_BY_LOCATION_ERROR = '@utilities/UPDATE_BY_LOCATION_ERROR',
  GET_LABELS = '@utilities/GET_LABELS',
  GET_LABELS_SUCCESS = '@utilities/GET_LABELS_SUCCESS',
  GET_LABELS_ERROR = '@utilities/GET_LABELS_ERROR',
  CREATE_LABEL = '@utilities/CREATE_LABEL',
  CREATE_LABEL_SUCCESS = '@utilities/CREATE_LABEL_SUCCESS',
  CREATE_LABEL_ERROR = '@utilities/CREATE_LABEL_ERROR',
  PRINT_LABEL = '@utilities/PRINT_LABEL',
  PRINT_LABEL_SUCCESS = '@utilities/PRINT_LABEL_SUCCESS',
  PRINT_LABEL_ERROR = '@utilities/PRINT_LABEL_ERROR',
  CLEAR_FEEDBACK_MESSAGE = "@utilities/CLEAR_FEEDBACK_MESSAGE",
}

export interface UpdateUserAction {
    type: typeof UtilitiesActionsTypes.UPDATE_USER
    payload: any
}

export interface UpdateUserSuccessAction {
    type: typeof UtilitiesActionsTypes.UPDATE_USER_SUCCESS
    payload:  User[],
}

export interface UpdateUserErrorAction {
    type: typeof UtilitiesActionsTypes.UPDATE_USER_ERROR
    payload: any
}

export interface GetAllLocationsAction {
    type: typeof UtilitiesActionsTypes.GET_ALL_LOCATIONS,
    payload: any
}

export interface GetAllLocationsSuccessAction {
    type: typeof UtilitiesActionsTypes.GET_ALL_LOCATIONS_SUCCESS
    payload: UserLocation[]
}

export interface GetAllLocationsErrorAction {
    type: typeof UtilitiesActionsTypes.GET_ALL_LOCATIONS_ERROR
    payload: any
}

export interface ClearFeedbackMessageAction {
    type: typeof UtilitiesActionsTypes.CLEAR_FEEDBACK_MESSAGE;
}

export interface UpdateByLocationAction {
    type: typeof UtilitiesActionsTypes.UPDATE_BY_LOCATION
    payload: any
}

export interface UpdateByLocationSuccessAction {
    type: typeof UtilitiesActionsTypes.UPDATE_BY_LOCATION_SUCCESS
    payload:  UserLocation[],
}

export interface UpdateByLocationErrorAction {
    type: typeof UtilitiesActionsTypes.UPDATE_BY_LOCATION_ERROR
    payload: any
}

export interface GetLabelsAction {
    type: typeof UtilitiesActionsTypes.GET_LABELS,
    payload: any,
}

export interface GetLabelsSuccessAction {
    type: typeof UtilitiesActionsTypes.GET_LABELS_SUCCESS,
    payload: LabelPayload[],
}

export interface GetLabelsErrorAction {
    type: typeof UtilitiesActionsTypes.GET_LABELS_ERROR,
    payload: any,
}

export interface CreateLabelAction {
    type: typeof UtilitiesActionsTypes.CREATE_LABEL,
    payload: LabelPayload,
}

export interface CreateLabelSuccessAction {
    type: typeof UtilitiesActionsTypes.CREATE_LABEL_SUCCESS,
    payload: any,
}

export interface CreateLabelErrorAction {
    type: typeof UtilitiesActionsTypes.CREATE_LABEL_ERROR,
    payload: any,
}

export interface PrintLabelAction {
    type: typeof UtilitiesActionsTypes.PRINT_LABEL,
    payload: PrintLabel,
}

export interface PrintLabelSuccessAction {
    type: typeof UtilitiesActionsTypes.PRINT_LABEL_SUCCESS,
    payload: any,
}

export interface PrintLabelErrorAction {
    type: typeof UtilitiesActionsTypes.PRINT_LABEL_ERROR,
    payload: any,
}

export type UtilitiesActions =
    | UpdateUserAction
    | UpdateUserSuccessAction
    | UpdateUserErrorAction
    | GetAllLocationsAction
    | GetAllLocationsSuccessAction
    | GetAllLocationsErrorAction
    | UpdateByLocationAction
    | UpdateByLocationSuccessAction
    | UpdateByLocationErrorAction
    | ClearFeedbackMessageAction
    | GetLabelsAction
    | GetLabelsSuccessAction
    | GetLabelsErrorAction
    | CreateLabelAction
    | CreateLabelSuccessAction
    | CreateLabelErrorAction
    | PrintLabelAction
    | PrintLabelSuccessAction
    | PrintLabelErrorAction